<template>
    <div>
        <v-toolbar dark color="orange" dense >
            <div v-if="tipo==1">Medicamentos</div>
            <div v-if="tipo==2">Material de curación</div>
            <v-spacer/>
            <v-btn icon @click="dialogCatMed=true"><v-icon>mdi-plus</v-icon></v-btn>
            <v-btn icon @click="list()"><v-icon>mdi-sync</v-icon></v-btn>
        </v-toolbar>

        <div class="d-flex flex-row align-center ml-3">
            <div>Medicamento</div>
            <v-switch
                loading="loadingCarga"
                class="mx-3"
                :false-value="1"
                :true-value="2"
                v-model="tipo"
            />
            <div>Material de curación</div>
        </div>

        <v-snackbar
            color="green"
            v-model="snackbarAgrega"
            :timeout="3000"
        >
            Guardado
        </v-snackbar>

        <v-snackbar
            color="red"
            v-model="snackbarBorra"
            :timeout="3000"
        >
            Borrado
        </v-snackbar>

        <list-meds
            :dialogModal.sync="dialogCatMed"
            :tipo="tipo"
            :medicamento.sync="esteMed"
        />
        <v-data-table

            :headers="headers"
            :items="itemsPadecimientos"
            disable-sort
            item-key="id"
            :loading="loadingCarga"
            class="elevation-1"
            hide-default-footer
        >
            <template v-slot:[`item.tipo`]="{ item }">
                <v-icon v-if="item.tipo==1" color="blue">mdi-pill</v-icon>
                <v-icon v-if="item.tipo==2" color="purple">mdi-needle</v-icon>
            </template>

            <template v-slot:[`item.especificacion`]="{ item }">
                <span v-html='item.especificacion'/>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
                <v-btn icon @click="borra(item.id)">
                    <v-icon color="red">mdi-cancel</v-icon>
                </v-btn>
            </template>
        </v-data-table>

    </div>
</template>

<script>
export default {
    mounted(){
        this.list()
        //console.log(this.diag)
    },

    props:{
        diag:Number
    },

    components:{
        'list-meds':()=>import('@/components/farmacia/FarmaciaListMeds'),
    },
    data:()=>({
        itemsPadecimientos:[],
        dialogCatMed:false,
        listaPadecimienos:[],
        esteMed:0,
        loadingNuevo:false,
        loadingCarga:false,
        tipo:1,
        headers:[
            { text: '', value: 'tipo', width:'5%'},
            { text: 'Clave', value: 'clave', width:'10%'},
            { text: 'Concepto', value: 'concepto'},
            { text: 'Especificacion', value: 'especificacion'},
            { text: '', value: 'acciones', width:'10%'},
        ],

        snackbarAgrega:false,
        snackbarBorra:false,
    }),

    methods:{

        async borra2(id){
            try{
                const req = await this.$http({
                    url:'/insabi/borra',
                    method:'POST',
                    params:{
                        diag:this.diag,
                        med:id
                    }
                })
                if (req.data.estado){
                    this.snackbarBorra = true
                    this.list()
                }
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borra(id){
            this.$swal.fire({
                title:'¿Desea borrar?',
                text:'Esta acción no se puede deshacer',
                icon: 'warning',
                showCancelButton: true,

                confirmButtonText:'Borrar',
                cancelButtonText:'No borrar',

                confirmButtonColor: 'red',
                cancelButtonColor: 'blue',
            }).then((result)=>{
                if (result.value){
                    this.borra2(id)
                }
            })
        },
        async list(){
            this.itemsPadecimientos=[]
            this.loadingCarga=true
            try{
                const req = await this.$http({
                    url:'/insabi/listMed',
                    method:'POST',
                    params:{
                        diag:this.diag,
                    }
                })
                this.loadingCarga=false
                this.itemsPadecimientos=req.data.filter(itm=>itm.tipo == this.tipo)
            }catch(err){
                this.loadingCarga=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async guarda(){
            try{
                const req = await this.$http({
                    url:'/insabi/guardaMed',
                    method:'POST',
                    params:{
                        diag:this.diag,
                        med:this.esteMed
                    }
                })
                if (req.data.estado){
                    this.snackbarAgrega = true
                    this.list()
                }
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        }
    },

    watch:{
        diag(){
            this.list()
            //console.log(v)
        },
        esteMed(v){
            if(v){
                //console.log(v)
                this.guarda()
            }
        },
        tipo(){
            this.list()
        },

    }

}
</script>

<style>

</style>